import React from 'react'
import { Container, Row } from 'react-bootstrap'

export default function About() {
  return (
    <Container>
        <Row className='text-center p-4'>
            <h1 className='main-tagline'>About Me</h1>
        </Row>
        <Row className='text-center p-4'>
            <p>
                I am a local handyman in the Kitchener Waterloo Cambridge area. I
                Have over 5 years of experience in the construction industry,
                everything from building renovations to welding and metalwork
                I've done it all. I am a hard worker and I take pride in my work and
                I am always looking to expand my knowledge and skills.
            </p>
        </Row>
        <Row className='text-center p-4'>
            <h2>Services</h2>
            <p>
                I offer a wide range of services from painting to landscaping and
                everything in between. If you have a project in mind and you're not
                sure if I can do it, just give me a call and I'll be happy to help.
            </p>
        </Row>
    </Container>
  )
}
