import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

export default function Home() {
  return (
    <Container>
      <Row className="text-center p-4">
        <h1 className="main-tagline">Your Local Fully Insured Affordable Handyman</h1>
        <h2 className="main-tagline">
          In the Kitchener Waterloo Cambrige area
        </h2>
        <h3 className="main-tagline">Call 226-868-1071</h3>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center align-middle p-5">
          <div>
            <h2>Services</h2>
            <ul>
              <li>Painting</li>
              <li>Landscaping</li>
              <li>Yard Work</li>
              <li>General Repairs</li>
              <li>And More</li>
            </ul>
          </div>
        </Col>
        <Col className="d-flex justify-content-center align-middle p-5">
          <Image
            src="./photo1.png"
            fluid
            className="main-image"
            rounded
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center align-middle p-5">
          <Image
            src="./photo2.png"
            fluid
            rounded
          />
        </Col>
        <Col className="d-flex justify-content-center p-5">
          <div>
            <h2>Who I Am</h2>
            <p>
              I am a local handyman in the Kitchener Waterloo Cambridge area. I
              Have over 5 years of experience in the construction industry,
              everything from building renovations to welding and metalwork
              I've done it all. I am a hard worker and I take pride in my work and
              I am always looking to expand my knowledge and skills.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
