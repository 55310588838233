import React from 'react'
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export default function Navigation() {
  const navigate  = useNavigate();
  async function handleContactClick(e) {
    navigate('/contact');
  }

  return (
    <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">K-W-C Handyman</Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
            <Nav className="mr-auto ">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
            
          </Navbar.Collapse>
          <Button variant="outline-success" className='justify-right' onClick={()=> handleContactClick()}>Contact Me</Button>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        </Container>
      </Navbar>
  )
}
