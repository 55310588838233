import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import About from './About';
import Contact from './Contact';
import Home from './Home';
import Navigation from './Navigation';
import WebFont from 'webfontloader';
import { useEffect } from 'react';


function App() {
  useEffect(() => {
  WebFont.load({
    google: {
      families: ['Montserrat']
    }
  });
 }, []);
  return (
    <>
    <Navigation/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    </>
  );
}

export default App;
