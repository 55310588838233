import React from 'react'
import { Container, Row } from 'react-bootstrap'

export default function Contact() {
  return (
    <Container>
        <Row className='text-center p-4'>
            <h1 className='main-tagline'>Contact Me</h1>
        </Row>
        <Row className='text-center p-4'>
            <p>
                If you have any questions or would like to get a quote for a project
                you have in mind, please feel free to contact me at any time.
            </p>
        </Row>
        <Row className='text-center p-4'>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe7xjL4w9-WAQvZ4GnB4XA4dHH-itqR05S56eerKMKPOzQlzg/viewform?embedded=true" width="640" height="947" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </Row>
    </Container>
  )
}
